<template>
    <section class="bg-white mt-4">
        <tabla-general :data="dataTable" alto="calc(100vh - 365px)" size="large" class-header="text-general f-16">
            <template slot="cabecera-izquierda">
                <div class="col-auto px-4 py-3 text-general f-18 f-600">
                    {{ dataTable.length }} pedidos pendientes por comisión
                </div>
            </template>
            <el-table-column prop="id" label="ID Pedido" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="cliente" label="Cliente" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ row.cliente }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha del pedido" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ row.fecha_pedido | helper-fecha('DD MMM Y') }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Valor del pedido" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ separadorNumero(row.valor_final)  }}</p>
                </template>
            </el-table-column>
        </tabla-general>
    </section>
</template>
<script>
import Comisiones from '~/services/comisiones/comisiones_pagadas';
export default {
    data(){
        return{
            dataTable:[]
        }
    },
	mounted(){
        this.getTabla()
    },
	methods: {
        async getTabla(){
            try {
                const {data} = await Comisiones.getComisionesVigentesTendero({idTendero: this.$usuario.tienda.id})
                this.dataTable = data.data
            } catch (e){
                this.error_catch(e)
            }
        },


    }
}
</script>
<style lang="scss">
</style>